// HeroSection.js
import React from "react";
import BannerVideo from "../../videos/home.mp4";

import { Box } from "@mui/material";
import BackgroundVideo from "./BackgroundVideo";
import rbgIcon from "../../images/rbg.png";
import "./css/homeBanner.css";
const HeroSection = () => {
  return (
    <>
      <Box className="homeBannerSection" sx={{ py: 0 }}>
        <BackgroundVideo />
        <Box className="bannerTextWrp">
          <Box component="img" src={rbgIcon} className="img-fluid" alt="RBG" />
          <h3>We Build Brands That Matter In Culture...</h3>
        </Box>

        <Box className="background-video">
        
          <Box
              component="video"
              className="scroll-video"
              src={BannerVideo}
              autoPlay
              loop
              muted
              
              
          />
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;
