import { Box, Container } from "@mui/material";
import React from "react";
import ContactForm from "./contact/ContactForm";

import "../components/css/contactPage.css";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us for Assistance and Inquiries</title>
        <meta
          name="description"
          content="Visit our Contact Us page for assistance with any questions, feedback, or inquiries you may have. Fill out the form provided or reach out to us directly for prompt assistance."
        />
      </Helmet>
      <Box component="section" className="contactPage">
        <Container>
          <ContactForm />
        </Container>
      </Box>
    </>
  );
};

export default Contact;
