import { GraphQLClient } from 'graphql-request';
const Hygraph = new GraphQLClient(
  'https://us-west-2.cdn.hygraph.com/content/cm1fowrwv018o07uumtqatdg3/master',
  {
    headers: {
      Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3Mjg0ODM2NDcsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuaHlncmFwaC5jb20vdjIvY20xZm93cnd2MDE4bzA3dXVtdHFhdGRnMy9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC11cy13ZXN0LTIuaHlncmFwaC5jb20vIiwic3ViIjoiMTNhZjAyNGItNThmYS00ZmYyLWE5YzctZWU0ZmMzYTdlOWY3IiwianRpIjoiY20yMXlqYWUyMHA0bzA3bG80Y2hkY3BsdiJ9.qIlxLZQyg_wIOsdTCi-vs3v9Hgtf7FRsC853GYvUJtm9wI75U1DsGWnFPapYH-4M8aJc4Gv-1-AMC5DSb5rY0fgYrwuesjO3NUXUFAY-8hPnJhv0Q8nh-Gpd_ttNmT2g78Ghy4DZtVx7TzxaDsTQiX29WS3R5drk2Cf6QBZB4FvPYdEWG9dVN47fyLUKJWut6rAXuk_EhjxXjIpQlWLqJetFeIceDHPS0_sCRt4I77M7OIT8V7zdDOzLh1-RJINzoC5dWww0ohgJii8MoHOu7AA-gNNtl1dz72MFJOEN0ciR7B3kcpzJDza_lszDELGi5xVIk9juQE_QxGmg4N-di-FIcAGnHzZREHX9PN6WrkWSWPl3SuTLfMT83zxesByfXaeQacBxLBxjdnBekbJKm6biuw6Rl3cgwgaH_R-ynGr500Wb2zPGYxt5nw0StzH98Ul_NuPMaseFh8Yyd8bKeuu2_-lyuV_ORwroDkdjvkNv3RfWh19r55Yj4rXp1SGmeMZTGVxowSUjTZPkQGVm7xXSeLbwCkUV9c5bs6P2tBnoahbeSCvGbbRMdfyape8PZLYUKekB1RGRdTVYeGrJSdvwb4-wYSRrOYziG_TZFPtv1ieeaAo1S1bm6C5fnOVETjGCfs_lbaZC0rBmWe3Nate-dp15tBgjQh28zYUSVaM'
    }
  }
);
export default Hygraph;
