import React from 'react'
import PageHeader from '../components/PageHeader'

const ComingSoon = () => {
  return (
    <>
        <PageHeader title={'Coming Soon...'} />
    </>
  )
}

export default ComingSoon