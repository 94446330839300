//How It Work Image
import step1Img from '../src/images/howitwork/1.jpg'
import step2Img from '../src/images/howitwork/2.jpg'
import step3Img from '../src/images/howitwork/3.jpg'
import step4Img from '../src/images/howitwork/4.jpg'
import step5Img from '../src/images/howitwork/5.jpg'


//Banner Image Trail on hover
import img1 from '../src/images/banner/1.jpg'
import img2 from '../src/images/banner/2.jpg'
import img3 from '../src/images/banner/3.jpg'
import img4 from '../src/images/banner/4.jpg'
import img5 from '../src/images/banner/5.jpg'
import img6 from '../src/images/banner/6.jpg'
import img7 from '../src/images/banner/7.jpg'
import img8 from '../src/images/banner/8.jpg'
import img9 from '../src/images/banner/9.jpg'
import img10 from '../src/images/banner/10.jpg'


// Team Images
import Manan from '../src/images/manan.png'
// import Jim from '../src/images/jim.png'
import christian from '../src/images/christian.png'
// import mike from '../src/images/mike.png'
import Sarah from '../src/images/sarah.png'
// import Rags from '../src/images/rags.png'
import chase from '../src/images/hero.png'


//Portfolio Home Images

import home_gaiaspa from '../src/images/portfolio/home/gaiaspa.jpg'
import home_tbt from '../src/images/portfolio/home/tbt.jpg'
import home_beautiLondge from '../src/images/portfolio/home/beautiLondge.jpg'
import home_yachlife from '../src/images/portfolio/home/yachlife.jpg'
import home_obx from '../src/images/portfolio/home/obx.jpg'
import home_simply from '../src/images/portfolio/home/simply.jpg'

//Portfolio Images
// import beautyLounge from  '../src/images/portfolio/beautyLounge.jpg'
// import derrickTerrant from  '../src/images/portfolio/derrickTerrant.jpg'
// import bridgeTeachers from  '../src/images/portfolio/bridgeTeachers.jpg'
// import compassRose from  '../src/images/portfolio/compassRose.jpg'
// import simply from  '../src/images/portfolio/simply.jpg'



//Portfolio Inner page Images
import beautyLounge_page from  '../src/images/portfolio/inner/beauty.jpg'
import yacht_life_page from  '../src/images/portfolio/inner/yacht_life.jpg'
import bridgeTeachers_page from  '../src/images/portfolio/inner/thebridge.jpg'




//Services Page Images
//Web Design
import wd_slide_01 from '../src/images/services/webdesign/webDesign_banner_1.jpg'
import wd_slide_02 from '../src/images/services/webdesign/webDesign_banner_2.jpg'
import wd_slide_03 from '../src/images/services/webdesign/webDesign_banner_3.jpg'

import wd_icon_1 from '../src/images/services/webdesign/icons/react.png'
import wd_icon_2 from '../src/images/services/webdesign/icons/square.png'
import wd_icon_3 from '../src/images/services/webdesign/icons/elementor.png'
import wd_icon_4 from '../src/images/services/webdesign/icons/woo.png'
import wd_icon_5 from '../src/images/services/webdesign/icons/shopify.png'
import wd_icon_6 from '../src/images/services/webdesign/icons/magento.png'
import wd_icon_7 from '../src/images/services/webdesign/icons/wordpress.png'
import wd_icon_8 from '../src/images/services/webdesign/icons/webflow.png'
import wd_icon_9 from '../src/images/services/webdesign/icons/duda.png'
import wd_icon_10 from '../src/images/services/webdesign/icons/wix.png'

//SEO Design
import seo_slide_01 from '../src/images/services/seo/seo_slide_4.jpg'
import seo_slide_02 from '../src/images/services/seo/seo_slide_3.jpg'
import seo_slide_03 from '../src/images/services/seo/seo_slide_5.jpg'
// import seo_slide_02 from '../src/images/banner/5.jpg'

import seo_icon_1 from '../src/images/services/seo/icons/ahrefs.jpg'
import seo_icon_2 from '../src/images/services/seo/icons/frog_seo.jpg'
import seo_icon_3 from '../src/images/services/seo/icons/google_analytics.jpg'
import seo_icon_4 from '../src/images/services/seo/icons/google_search-console.jpg'
import seo_icon_5 from '../src/images/services/seo/icons/kw.jpg'
import seo_icon_6 from '../src/images/services/seo/icons/majestic.jpg'
import seo_icon_7 from '../src/images/services/seo/icons/moz.jpg'
import seo_icon_8 from '../src/images/services/seo/icons/semrush.png'
import seo_icon_9 from '../src/images/services/seo/icons/u.jpg'
import seo_icon_10 from '../src/images/services/seo/icons/yoast.jpg'

//Mobile Development
import mob_icon_1 from '../src/images/services/mobile/icons/Java.png'
import mob_icon_2 from '../src/images/services/mobile/icons/css.png'
import mob_icon_3 from '../src/images/services/mobile/icons/flutter.png'
import mob_icon_4 from '../src/images/services/mobile/icons/html.png'
import mob_icon_5 from '../src/images/services/mobile/icons/javascript.png'
import mob_icon_6 from '../src/images/services/mobile/icons/kotlin.png'
import mob_icon_7 from '../src/images/services/mobile/icons/obj.png'
import mob_icon_8 from '../src/images/services/mobile/icons/react.png'
import mob_icon_9 from '../src/images/services/mobile/icons/swift.jpg'
import mob_icon_10 from '../src/images/services/mobile/icons/xamarin.png'


// SMO
import smo_icon_1 from '../src/images/services/smo/icons/facebook.png'
import smo_icon_2 from '../src/images/services/smo/icons/youtube.png'
import smo_icon_3 from '../src/images/services/smo/icons/instagram.png'
import smo_icon_4 from '../src/images/services/smo/icons/twitter.png'
import smo_icon_5 from '../src/images/services/smo/icons/tiktok.png'
import smo_icon_6 from '../src/images/services/smo/icons/snapchat.png'
import smo_icon_7 from '../src/images/services/smo/icons/linkedIn.png'
import smo_icon_8 from '../src/images/services/smo/icons/pintrest.png'
import smo_icon_9 from '../src/images/services/smo/icons/reddit.png'
import smo_icon_10 from '../src/images/services/smo/icons/whatsapp.png'



//Portfolio Logo
import p_lopo_1 from './images/clients_logo/25.png'
import p_lopo_2 from './images/clients_logo/39.png'
import p_lopo_3 from './images/clients_logo/40.png'
import p_lopo_4 from './images/clients_logo/12.png'
import p_lopo_7 from './images/clients_logo/16.png'
import p_lopo_8 from './images/clients_logo/20.png'
import p_lopo_11 from './images/clients_logo/37.png'
import p_lopo_12 from './images/clients_logo/27.png'
import p_lopo_15 from './images/clients_logo/9.png'
import p_lopo_16 from './images/clients_logo/8.png'
import p_lopo_17 from './images/clients_logo/10.png'
import p_lopo_18 from './images/clients_logo/38.png'
import p_lopo_19 from './images/clients_logo/23.png'
import p_lopo_20 from './images/clients_logo/42.png'
import p_lopo_21 from './images/clients_logo/24.png'
import p_lopo_22 from './images/clients_logo/22.png'
import p_lopo_23 from './images/clients_logo/17.png'
import p_lopo_24 from './images/clients_logo/36.png'
import p_lopo_25 from './images/clients_logo/29.png'
import p_lopo_26 from './images/clients_logo/21.png'
import p_lopo_28 from './images/clients_logo/14.png'
import p_lopo_29 from './images/clients_logo/15.png'
import p_lopo_30 from './images/clients_logo/4.png'
import p_lopo_31 from './images/clients_logo/6.png'
import p_lopo_32 from './images/clients_logo/5.png'
import p_lopo_33 from './images/clients_logo/26.png'
import p_lopo_34 from './images/clients_logo/18.png'
import p_lopo_35 from './images/clients_logo/13.png'
import p_lopo_36 from './images/clients_logo/3.png'
import p_lopo_39 from './images/clients_logo/2.png'
import p_lopo_40 from './images/clients_logo/19.png'
import p_lopo_41 from './images/clients_logo/11.png'
import p_lopo_42 from './images/clients_logo/1.png'

export const HomePageData = {
        banner: {
            bannerTitle: '',
            trailImage: [
                {
                    id:1,
                    urlImg:img1
                },
                {
                    id:2,
                    urlImg:img2
                },
                {
                    id:3,
                    urlImg:img3
                },
                {
                    id:4,
                    urlImg:img4
                },
                {
                    id:5,
                    urlImg:img5
                },
                {
                    id:6,
                    urlImg:img6
                },
                {
                    id:7,
                    urlImg:img7
                },
                {
                    id:8,
                    urlImg:img8
                },
                {
                    id:9,
                    urlImg:img9
                },
                {
                    id:10,
                    urlImg:img10
                },
            ]
        },
        aboutus: {
            title: " We unlock the potential of your business with creative design",
            description: `Howdy howdy, We represent Rebrand gurus, A complete gourmet  digital creative agency in the heart of TEXAS. Unique, belligerent and visionary in nature, we help SME and boot strapped ventures to grow with great return on investment through the digital media representation.`,
            leftImage : {
                
                alt : "Left Image",
                
            },
            rightImage : {
                alt : "Right Image",
            },
            button : {
                text : "Explore Us",
                link : "/about"
            },
            hoverBox : {
                heading : 'Prada Marfa',
                description : `Prada Marfa is a permanent sculptural art installation by artists Elmgreen & Dragset, located along U.S. Route 90 in Jeff Davis County, Texas, United States, 1.4 miles (2.3 km) northwest of Valentine, and about 26 miles (42 km) northwest of Marfa.  `
            }
        },
        whyChooseUs: {
            title: "Why Choose Us?",
            slideOne:{
                title:'Strategy, Audience, & ROI',
                subTitle: 'Why Choose Us',
                description: 'Choose us because we care!! Not just about aesthetics but also longevity, scalability and profitability of your business today and forever. Very few envision your vision and we are on the top spot there.. We dont sell, we build enterprises and value system together... ',
                researchTool:[
                    {
                        id:1,
                        title:"Google",
                        link:"https://maps.app.goo.gl/WeRmuNKyT3zGeTMQ9",
                    },
                    {
                        id:2,
                        title:"Pinterest",
                        link:"https://in.pinterest.com/rebrandgurus/",
                    },
                    {
                        id:3,
                        title:"Instagram",
                        link:"https://www.instagram.com/rebrandgurusofficial/?next=%2F",
                    }
                ],
                points:[
                    {
                        id:1,
                        title:'Strategy',
                        description:'We are just not about beauty but brains too.. Our customised strategy put together means, Dinero dinero',
                        number:'40',
                        numberPrefix:'%'
                    },
                    {
                        id:2,
                        title:'Target Audience',
                        description:'We focus on striking a balance between who is your potential buyer and how to reach them. Knowing the right door is the key!!',
                        number:'20',
                        numberPrefix:'%'
                    },
                    {
                        id:3,
                        title:'ROI',
                        description:'All hail the dollar bill. We eventually are all about making you profits with quality and long term thought!! ',
                        number:'40',
                        numberPrefix:'%'
                    }
                ]
            },
            SlideTwo:{
                ourNumbers:[
                    {
                        id:1,
                        title:'Project completed',
                        number:'500',
                        numberPrefix:'+'
                    },
                    {
                        id:2,
                        title:'Happy customers',
                        number:'93',
                        numberPrefix:'%'
                    },
                    {
                        id:3,
                        title:'Years experiences',
                        number:'3',
                        numberPrefix:''
                    },
                    {
                        id:4,
                        title:'ROI Generation',
                        number:'27',
                        numberPrefix:'%'
                    },
                ]
            },
            slideThree:{
                title :`Let's create a great vision together!`,
                subTitle:'Do you have a Dream to Fulfill?',
                button:{
                    text:`Let's Connect`,
                    url:'/contact'
                }
            }
        },
        howItWorkSec:{
            title:"Insync Approach",
            subTitle: "Process Flow",
            steps:[
                {
                    step:'01',
                    title:'Consultation',
                    content:`Understand client needs, goals, and preferences through detailed discussions and brainstorming sessions.`,
                    image: step1Img
                },
                {
                    step:'02',
                    title:'Planning',
                    content:`Develop a project roadmap, including timelines, milestones, and resource allocation, ensuring alignment with client expectations.`,
                    image: step2Img
                },
                {
                    step:'03',
                    title:'Design',
                    content:`Create wireframes and mockups, focusing on user experience, visual appeal, and branding consistency.`,
                    image: step3Img
                },
                {
                    step:'04',
                    title:'Development',
                    content:`Code the website, integrating necessary functionalities, and ensure responsive design across devices.`,
                    image: step4Img
                },
                {
                    step:'05',
                    title:'Testing & Launch',
                    content:`Conduct thorough testing for bugs, optimize performance, and deploy the website, followed by ongoing support and maintenance.`,
                    image: step5Img
                },
            ]
        },
        TeamSection:{
            title:'At the Helm.. ',
            subTitle:'The Gurus',
            description: `With a collective  experience of more than 100 years, our core  team welcomes you to Join the bandwagon full of  youth, intelligence and experience carved just for your business excellence!!`,
            Team:[
                {
                    id: 1,
                    name: 'Manan Vashisht Sharma ',
                    position:'Chief Executive Officer / Founder',
                    image: Manan,
                    linkedIn: 'https://www.linkedin.com/in/manan-vashisht-a1672914/'
                },
                {
                    id: 2,
                    name: 'Shreya Jain',
                    position:'Associate Vice President ',
                    image: Sarah,
                    linkedIn: 'https://www.linkedin.com/in/shreya-jain28/'
                },
                {
                    id: 3,
                    name: 'Christian Bacasa',
                    position:'Head Consultant ',
                    image:christian,
                    linkedIn: 'https://www.linkedin.com/in/christianbacasa/'
                },
               
            ],
            Chase:{
                title:`Hello I'm Chase`,
                content:'Nice to meet you!!',
                image:chase
            }

        },
        HomePortfolio: {
            title:'Changing the World one Website at a time',
            subTitle:'Achievements',
            description: ``,
            portfolio: [
                {
                    id:0,
                    title:'The Gaia Spa',
                    imgURL: home_gaiaspa,
                    webURL: 'https://thegaiaspa.com/',
                    tag: ['Online Booking', 'Spa']


                },
                {
                    id:1,
                    title:'The Bridge Teachers',
                    imgURL: home_tbt,
                    webURL: 'https://thebridgeteachers.com/',
                    tag: ['Subscription', 'Online Course']


                },
                {
                    id:2,
                    title:'The Beauty Loundge',
                    imgURL: home_beautiLondge,
                    webURL: 'https://thebeautyloungeangus.ca/',
                    tag: ['Subscription', 'Online Course']


                },
                {
                    id:3,
                    title:'Yacht Life',
                    imgURL: home_yachlife,
                    webURL: 'https://yachtlife.tv/',
                    tag: ['Streaming', 'Luxury']

                },
                {
                    id:4,
                    title:'The Outer Banks Chef',
                    imgURL:home_obx ,
                    webURL: 'https://theouterbankschef.com/',
                    tag: ['Cooking', 'Luxury']


                },
                {
                    id:5,
                    title:'Jepsens Simply Rustic',
                    imgURL: home_simply,
                    webURL: 'https://jepsenssimplyrustic.com/',
                    tag: ['eCommerce', 'Yoga']


                }
            ]
        }
}

export const PortfolioData = {
    number: [
        {
            id:1,
            title:"Wordpress",
            number:"200",
            postfix:"+",
            
        },
        {
            id:2,
            title:"Shopify",
            number:"110",
            postfix:"+",
            
        },
        {
            id:3,
            title:"Web Application",
            number:"24",
            postfix:"+",
            
        },
        {
            id:4,
            title:"Mobile Application",
            number:"18",
            postfix:"+",
            
        }
    ],
    portFolio : [
        {
            id:0,
            title:'Yacht Life TV',
            imgMainURL: yacht_life_page,
            logoUrl:p_lopo_1,
            webURL: 'https://yachtlife.tv/',
            tag: ['Live Streaming', 'Marine Life']

        },
        {
            id:1,
            title:'The Bridge Teachers',
            logoUrl:p_lopo_2,
            imgMainURL: bridgeTeachers_page,
            webURL: 'https://thebridgeteachers.com/',
            tag: ['Subscription', 'Online Course']

        
        },
        {
            id:2,
            title:'The Beauty Lounge',
            logoUrl:p_lopo_3,
            imgMainURL: beautyLounge_page,
            webURL: 'https://thebridgeteachers.com/',
            tag: ['Creative', 'Online Course']

        },
        {
            id:3,
            title:'Jepsens Simply Rustic',
            logoUrl:p_lopo_4,
            webURL: 'https://jepsenssimplyrustic.com/',
            tag: ['Yoga & Health '],
            category : "WooCommerce",
            reviewText :"Amazing❤️Sara has been so easy to work with & really is dedicated2 helping make my website look great & run smoothly I highly recommend them"
        
        },
        {
            id:4,
            title:'In-tension Training',
            logoUrl:'',
            webURL: 'https://in-tensiontraining.com/',
            tag: ['Sports'],
            category : "Wordpress",
            reviewText :"I had a great experience working with the folks at rebrandguru. Throughout the process of redesigning my website, Mike and Manan were very helpful, timely, and thorough. They completely exceeded my expectations, and I would highly recommend their services to anyone and everyone looking for web design."
        },
        {
            id:5,
            title:'Harris Water Main & Sewers',
            webURL: 'https://www.harriswatermainandsewers.com/',
            logoUrl:'',
            tag: ['Water Main & Sewer Contractors'],
            category : "Wordpress",
            reviewText: "I had an amazing experience with ReBrand group. They were extremely attentive to detail, provided a quick turnaround, and had excellent communication throughout the process. I am not the type to write a review as a favor, but our experience was genuinely exceptional. This is my company's fifth website in the past 15 years, and I can honestly say that this experience far exceeded the previous ones. The group wasn't just composed of designers and developers; they also had a deep understanding of SEO and how each aspect of the website would impact our search engine optimization. I would highly recommend them to everyone"
        },
        {
            id:6,
            title:'Nutrition',
            webURL: 'https://nutritionhf.com/',
            logoUrl:p_lopo_7,
            tag: ['Health and wellness'],
            category : "woocommerce",
            reviewText :"Manan and Sarah at ReBrand Gurus did lovely work rebuilding my website in a very short timeframe! I highly recommended them for such projects."
        },
        {
            id:7,
            title:'Dronomy',
            webURL: 'https://dronomy.io/',
            logoUrl:p_lopo_8,
            tag: ['Drone Sofware'],
            category : "Shopify",
            reviewText :`Manan and this team is awesome. I've used him for my e-commerce site building projects and he always delivers ahead of schedule & quality is great. Moving forward the only time I would use someone else is if he's unavailable. Highly recommend!"`
        },
        {
            id:8,
            title:'Elijahs Choice Mohel',
            webURL: 'https://elijahschoicemohel.com/',
            logoUrl:'',
            tag: ['Health'],
            category : "Wordpress",
            reviewText :"Mike and Ash really went above and beyond making sure my website was perfect! I was very particular about how I wanted everything to look, and they were very responsive in making sure everything was taken care of! They added cool new functions to the site and made everything look clean and professional."
        },
        {
            id:9,
            title:'Ocean Breeze ',
            logoUrl:'',
            webURL: 'https://oceanbreezeswimwear.com/',
            tag: ['Clothes'],
            category : "Shopify",
            reviewText :"Sarah and David were absolutely amazing with revamping my website! They were so responsive and really made the process so easy. I would definitely recommend anyone using them."
        },
        {
            id:10,
            title:'Hello Holiday ',
            logoUrl:p_lopo_11,
            webURL: 'https://shophelloholidays.com/',
            tag: ['Decoration Products'],
            category : "Shopify",
            reviewText :"They completely revamped my website making it easier to navigate! They are quick to respond to all my requests and very professional and forward thinking! They are a remarkable team!"
        },
        {
            id:11,  
            title:'Skateboard Lessons Atlanta',
            logoUrl:p_lopo_12,
            webURL: 'https://skateatl.com/',
            tag: ['Games'],
            category : "woocommerce",
            reviewText :"They outlined a game plan, and executed. Met our ambitious deadline. Amazing communication, very personalized. Website looks amazing n 5 stars all the way!!!"
        },
        {
            id:12,
            title:'Jeffmiller Landscapes',
            logoUrl:'',
            webURL: 'https://jeffmillerlandscapes.com/',
            tag: ['Constructions '],
            category : "Wordpress",
            reviewText :"They were professional and deliver results since day 1. Working with them was a great choice!"
        },
        {
            id:13,
            title:'Funzie',
            logoUrl:'',
            webURL: 'https://j4funzies.com/',
            tag: ['Gift  Store'],
            category : "WooCommerce",
            reviewText :"This company is outstanding. The quality of their work and customer service ethic are excellent, and their work for us has been stellar. I recommend them without reservation."
        },
        {
            id:14,    
            title:'Perfectly Imperfect',
            logoUrl:p_lopo_15,
            webURL: 'https://perfectlyimperfectliving.org/',
            tag: ['Yoga & Health '],
            category : "woocommerce",
            reviewText :"Working with Rebrand Gurus on the development of my site has been a blessing. Rarely in business these days can you team up with a creative business group that listens so closely to your vision, embraces that vision and assists in transforming it to reality. They have shown a deep level of commitment and dedication to this project, always making themselves available to my questions and concerns immediately. I trust them, can rely on them and look forward to a long-term ongoing web maintenance and development planning relationship. A truly rare gem in the business world today."
        
        },
        {
            id:15,
            title:'The Gaia Spa',
            logoUrl:p_lopo_16,
            webURL: 'https://thegaiaspa.com/',
            tag: ['Spa & Wellness'],
            category : "Woocommerce",
            reviewText :"Manan and Sarah at ReBrand Gurus did lovely work rebuilding my website in a very short timeframe! I highly recommended them for such projects."
        },
        {
            id:16,
            title:'Plimsoll Media',
            logoUrl:p_lopo_17,
            webURL: 'https://plimsollmedia.com/',
            tag: ['Creative Agency'],
            category : "Custom",
            reviewText :"Fantastic job in communicating and working within my hectic schedule. I would highly recommend this company'!!!! They really do care about your business' growth!!"
        },
        {
            id:17,
            title:'James H. Kelly',
            logoUrl:p_lopo_18,
            webURL: 'https://jharoldkellystories.com/',
            tag: ['Author'],
            category : "WooCommerce",
            reviewText :"They built my ecommerce site fast and sleek. Always helpful - I highly recommend for your business."
        },
        {
            id:18,
            title:'OBX Chef for Hire',
            logoUrl:p_lopo_19,
            webURL: 'https://theouterbankschef.com/',
            tag: ['Chef & Food '],
            category : "WooCommerce",
            reviewText :`Thanks to ReBrandGurus, "There was no issue that they left unaddressed." Thanks Manan & Mike to make website on time.`
        },
        {
            id:19,    
            title:'Anthony Dawkins, P.C',
            logoUrl:p_lopo_20,
            webURL: 'https://www.justice24-7.com/',
            tag: ['Law'],
            category : "Wordpress",
            reviewText :"Manan and his team are terrific. Very professional and they keep you informed! Highly recommend."
        
        },
        {
            id:20,
            title:'Doctor Eric A. Lazcano ',
            logoUrl:p_lopo_21,
            webURL: 'https://drlazcano.com/',
            tag: ['Health & Wellness'],
            category : "WooCommerce",
            reviewText: "Great company, works well to implement solutions in a timely manor, would definitely work with them again."
        
        },
        {
            id:21,
            title:'JoeMyStic',
            logoUrl:p_lopo_22,
            webURL: 'https://www.joemystic.com/',
            tag: ['Events '],
            category : "Wordpress",
            reviewText: "Rebrand Gurus did an excellent job on both of our websites at an affordable price. They were prompt in their response time and patient with our process. Highly recommend!"
        
        },
        {
            id:22,
            title:'Jennifer Stubbs',
            logoUrl:p_lopo_23,
            webURL: 'https://jenniferstubbs.com/',
            tag: ['Photography'],
            category : "WooCommerce",
            reviewText: "Manan and Sarah at ReBrand Gurus did lovely work rebuilding my website in a very short timeframe! I highly recommended them for such projects."
        
        },
        {
            id:23,
            title:'The Face Project',
            logoUrl:p_lopo_24,
            webURL: 'https://thefaceproject.org/',
            tag: ['Education'],
            category : "WooCommerce",
            reviewText: "This company consistently delivers excellent service and high-quality products. Highly recommended"
        
        },
        {
            id:24,
            title:'Prenatal Fit',
            logoUrl:p_lopo_25,
            webURL: 'hhttps://prenatalfit.com/',
            tag: ['Health'],
            category : "WooCommerce",
            reviewText: "Mike was wonderful to work with!"
        
        },
        {
            id:25,
            title:'Need Deed ',
            logoUrl:p_lopo_26,
            webURL: 'https://need-deed.com/',
            tag: ['Real estate'],
            category : "Wordpress",
            reviewText: "They've been a dependable partner, always delivering on time and with great quality."
        
        },
        {
            id:26,
            title:'SW Engineers',
            logoUrl:'',
            webURL: 'https://www.swengineers.com/',
            tag: ['Civil Engineer Services'],
            category : "Wordpress",
            reviewText: "Their expertise and insightful advice have been invaluable in achieving our goals."
        
        },
        {
            id:27,
            title:'Edy Boutique ',
            logoUrl:p_lopo_28,
            webURL: 'https://edyboutiques.com/',
            tag: ['Jewellery '],
            category : "Shopify",
            reviewText: "Rebrand Gurus did an excellent job on both of our websites at an affordable price. They were prompt in their response time and they were patient with our process. I would highly recommend them!"
        
        },
        {
            id:28,
            title:'Removable Veneers',
            logoUrl:p_lopo_29,
            webURL: 'https://removableveneersusa.com/',
            tag: ['Health'],
            category : "Shopify",
            reviewText: "Their project management is efficient and ensures milestones are met on time."
        
        },
        {
            id:29,
            title:'Clovers Compression ',
            logoUrl:p_lopo_30,
            webURL: 'https://cloverscompression.com/',
            tag: ['Health'],
            category : "Shopify",
            reviewText: `Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline and strict budget to create a first-class Shopify Website, and Will was the perfect partner. His guidance and advice was invaluable in producing that app which easily exceeded my expectations... the entire company was thrilled with it! Without any hesitation... highly recommended.`
        
        },
        {
            id:30,
            title:'PhotoBomb',
            logoUrl:p_lopo_31,
            webURL: 'https://photobombpb.com/',
            tag: ['Photography'],
            category : "Wordpress",
            reviewText:  `Thanks Mike for great support on our project. They not only showed their commitment and hard work in our project, but also helped us take care of some points that got missed by us and did not belong to their scope. It is a great help if the objective is in making the service successful, instead of just completing the project. The experience working with ReBrandGurus is great. Thanks and please keep on the same service and accountability!`
        
        },
        {
            id:31,
            title:'Copina Co',
            logoUrl:p_lopo_32,
            webURL: 'https://copinaco.com/',
            tag: ['Health and Beauty '],
            category : "Shopify",
            reviewText: "They handled my project efficiently and professionally. I'm very satisfied with the results."
        
        },
        {
            id:32,
            title:'Kitchen Around the World ',
            logoUrl:p_lopo_33,
            webURL: 'https://kitchenaroundtheworld.com/',
            tag: ['Food '],
            category : "Wordpress",
            reviewText: "Very impressed with their responsiveness and reliability. They exceeded my expectations!"
        
        },
        {
            id:33,
            title:'CPA Tanya',
            logoUrl:p_lopo_34,
            webURL: 'https://cpatanya.com/',
            tag: ['Financial Services / Accountant'],
            category : "Wordpress",
            reviewText: "Their customer support is top-notch. They are always helpful and quick to resolve any issues."
        
        },
        {
            id:34,
            title:'Aaack Packs',
            logoUrl:p_lopo_35,
            webURL: 'https://aaackpacks.com/',
            tag: ['Traveling Camping Products'],
            category : "Shopify",
            reviewText: "Exceptional team that goes above and beyond to ensure client satisfaction."
        
        },
        {
            id:35 ,
            title:'Cross Over Consulting',
            logoUrl:p_lopo_36,
            webURL: 'https://crossover-consulting.info/',
            tag: ['Consultant & Services'],
            category : "WooCommerce",
            reviewText: "Impressed by their innovative solutions that streamline processes and boost efficiency."
        
        },
        {
            id:36,
            title:'Accelerant Detection Dogs',
            logoUrl:'',
            webURL: 'https://atsk9detection.com/',
            tag: ['Animal trainer and Consultant'],
            category : "Wordpress   ",
            reviewText: "Their support team is reliable and resolves issues quickly with minimal downtime."
        
        },
        {
            id:37,
            title:`Spolied Rotten Children's Boutique`,
            logoUrl:'',
            webURL: 'https://spoiledrottenchildrensboutique.com/',
            tag: ['Dress Store for child'],
            category : "Shopify",
            reviewText: "They take a value-driven approach, delivering cost-effective solutions without compromising quality."
        
        },
        {
            id:38,
            title:'Ben & Abey ',
            logoUrl:p_lopo_39,
            webURL: 'https://benandabbydesign.com/',
            tag: ['Hand made child Gift '],
            category : "WooCommerce",
            reviewText: "They provide scalable solutions that grow with our business needs."
        
        },{
            id:39,
            title:'The Signature Society',
            logoUrl:p_lopo_40,
            webURL: 'https://thesignaturesociety.com/',
            tag: ['Events '],
            category : "Wordpress",
            reviewText: "Their creative design team exceeded our expectations with a fresh and modern look."
        
        },
        {
            id:40,
            title:'Jillian Hessel Pilates',
            logoUrl:p_lopo_41,
            webURL: 'https://jillianhessel.com/',
            tag: ['Health & Wellness'],
            category : "WooCommerce",
            reviewText: "They offer flexible solutions that adapt seamlessly to our evolving business environment."
        
        },
        {
            id:41,
            title:'El Gallo Fly Fishing Lodge',
            logoUrl:p_lopo_42,
            webURL: 'https://elgalloflyfishing.com/',
            tag: ['Fishing'],
            category : "WooCommerce",
            reviewText: "They are committed to continuous improvement, always striving to enhance their services."
        
        },
        {
            id:42,
            title:'Provo Pictures',
            logoUrl:'',
            webURL: 'https://provopictures.com/',
            tag: ['Photography'],
            category : "wordpress",
            reviewText: "Impressed by their use of innovative technology to solve complex challenges."
        
        },
    ]
}

export const ServicesPageData = {
    services:[
        {
            id:"1",
            title:"Website Redesign Services",
            slug:"web-design",
            sliderImage:[wd_slide_01, wd_slide_02, wd_slide_03],
            mainHeading: "Website Redesign Services",
            description:"At Rebrand gurus , we dont develop just the websites, we  make your dreams come true.Post COVID, Website is the face of any business and that only means it needs to be best to none.  This is where we capitalise and understand what a dream website is, for our prospects. Once thats understood is when we start talking about the technology to be used. We work with a questionnaire based theory where we pen down the wish list of a client to create their dream website. The BOT (Business optimisation team) at rebrand then does a complete 360 degrees review of the industry and then come up with the strategic solution for a non-redundant website that will stay relevant for next few years. We have a team of more than 50 designers, developers and quality managers who relentlessly work on any technology for either back end or front end to ensure 100% quality and timely delivery of a project.  ",
            // thumbImage: wd_slide_01,
            bgImage: wd_slide_01,
            bgColor:'#4792A5',
            icons:[
                {
                    id:'1',
                    src:wd_icon_1,
                    title:''
                },
                {
                    id:'2',
                    src:wd_icon_2,
                    title:''
                },
                {
                    id:'3',
                    src:wd_icon_3,
                    title:''
                },
                {
                    id:'4',
                    src:wd_icon_4,
                    title:''
                },
                {
                    id:'5',
                    src:wd_icon_5,
                    title:''
                },
                {
                    id:'6',
                    src:wd_icon_6,
                    title:''
                },
                {
                    id:'7',
                    src:wd_icon_7,
                    title:''
                },
                {
                    id:'8',
                    src:wd_icon_8,
                    title:''
                },
                {
                    id:'9',
                    src:wd_icon_9,
                    title:''
                },
                {
                    id:'10',
                    src:wd_icon_10,
                    title:''
                } 
            ]
        },
        {
            id:"2",
            title:"Search Engine Optimization",
            slug:"seo",
            sliderImage:[seo_slide_01, seo_slide_02, seo_slide_03],
            mainHeading: "Generate More Revenue: On Search",
            description:"Rebrand gurus is not your run in the mill kind of Digital rebranding company. We dont do SEO, SMO, PPC, YOU TUBE MARKETING AND CONTENT MARKETING the old school way. Our analysis is purely based on the current and upcoming trends. We dont believe in generating automated reports to win clients, We customise reports for the overall ROI enhancement of the Businesses, we are awarded. Our theory of competitive analysis at all times keeps our Digital work ahead of the curve and brings the best results for the clients..At Rebrand gurus, Number of hours are divided not to work traditionally , its purely divided to generate constant upgraded results with keywords and consistent content updating and optimisation. Our clients love this approach as they are never kept in dark with what we are doing since our work is easy to understand and strategise... ",
            // thumbImage: wd_slide_01
            bgImage: seo_slide_01,
            bgColor:'#43a047',
            icons:[
                {
                    id:'1',
                    src:seo_icon_1,
                    title:'Screaming Frog SEO Spider'
                },
                {
                    id:'2',
                    src:seo_icon_2,
                    title:'Screaming Frog SEO Spider'
                },
                {
                    id:'3',
                    src:seo_icon_3,
                    title:'Google Analytics'
                },
                {
                    id:'4',
                    src:seo_icon_4,
                    title:'Google Search Console'
                },
                {
                    id:'5',
                    src:seo_icon_5,
                    title:'KWFinder'
                },
                {
                    id:'6',
                    src:seo_icon_6,
                    title:'Majestic'
                },
                {
                    id:'7',
                    src:seo_icon_7,
                    title:'Moz'
                },
                {
                    id:'8',
                    src:seo_icon_8,
                    title:'Ubersuggest'
                },
                {
                    id:'9',
                    src:seo_icon_9,
                    title:'Ubersuggest'
                },
                {
                    id:'10',
                    src:seo_icon_10,
                    title:'Yoast SEO'
                } 
            ]
        },
        {
            id:"3",
            title:"Mobile App Development ",
            slug:"#",
            description:"We not only create great mobil apps but we rebrand them too , how?. At rebrand we make sure the apps are downloaded to the maximum effect , which very clearly means that we are looking at painting on that white canvas for our clients by virtue of their imagination and vision. We are solidly moving into the world of applications whether its mobil apps or progressive web apps, rebrand just does it right for you , customised and tailor made for you.. We ensure the most recent and efficient technology to create the app you always wanted to have. Rebrand Gurus will help you chose the right wireframe and design to get the X factor in this competitive world. Come , grow your audience and app downloads by giving us a chance..",
            bgImage: wd_slide_02,
            bgColor:'#C38439',
            icons:[
                {
                    id:'1',
                    src:mob_icon_1,
                    title:'Java'
                },
                {
                    id:'2',
                    src:mob_icon_2,
                    title:'css'
                },
                {
                    id:'3',
                    src:mob_icon_3,
                    title:'flutter'
                },
                {
                    id:'4',
                    src:mob_icon_4,
                    title:'html'
                },
                {
                    id:'5',
                    src:mob_icon_5,
                    title:'javascript'
                },
                {
                    id:'6',
                    src:mob_icon_6,
                    title:'kotlin'
                },
                {
                    id:'7',
                    src:mob_icon_7,
                    title:'obj'
                },
                {
                    id:'8',
                    src:mob_icon_8,
                    title:'react'
                },
                {
                    id:'9',
                    src:mob_icon_9,
                    title:'swift'
                },
                {
                    id:'10',
                    src:mob_icon_10,
                    title:'xamarin'
                } 
            ]
        },
        {
            id:"4",
            title:"Social Media Optimization",
            slug:"#",
            description:"Rebrand gurus is not your run in the mill kind of Digital rebranding company. We dont do SEO, SMO, PPC, YOU TUBE MARKETING AND CONTENT MARKETING the old school way. Our analysis is purely based on the current and upcoming trends. We dont believe in generating automated reports to win clients, We customise reports for the overall ROI enhancement of the Businesses, we are awarded. Our theory of competitive analysis at all times keeps our Digital work ahead of the curve and brings the best results for the clients..At Rebrand gurus, Number of hours are divided not to work traditionally , its purely divided to generate constant upgraded results with keywords and consistent content updating and optimisation. Our clients love this approach as they are never kept in dark with what we are doing since our work is easy to understand and strategise... ",
            bgImage: wd_slide_03,
            bgColor:'#7C1E35',
            icons:[
                {
                    id:'1',
                    src:smo_icon_1,
                    title:'Facebook'
                },
                {
                    id:'2',
                    src:smo_icon_2,
                    title:'YouTube'
                },
                {
                    id:'3',
                    src:smo_icon_3,
                    title:'Instagram'
                },
                {
                    id:'4',
                    src:smo_icon_4,
                    title:'Twitter'
                },
                {
                    id:'5',
                    src:smo_icon_5,
                    title:'TikTok'
                },
                {
                    id:'6',
                    src:smo_icon_6,
                    title:'Snapchat'
                },
                {
                    id:'7',
                    src:smo_icon_7,
                    title:'Pinterest'
                },
                {
                    id:'8',
                    src:smo_icon_8,
                    title:'Pinterest'
                },
                {
                    id:'9',
                    src:smo_icon_9,
                    title:'Reddit'
                },
                {
                    id:'10',
                    src:smo_icon_10,
                    title:'WhatsApp'
                } 
            ]
        },
        {
            id:"5",
            title:"Dedicated Resource",
            slug:"#",
            description:"We are the only company who gives you a full time dedicated resource at the cost , unbelievable. we make sure all the hassle of paying benefits like health benefits etc are being taken care by us. the work force or dedicated resource works like your shadow in our offices in your time zone. the efficiency and effective ness is kept intact if not increased by having a manager over each resource.At Rebrand we encourage a had shake work ethic  which just makes the relationship super strong and easy to maintain. Long term work caricature is what each company is looking at and rebrand fulfils it right to the T. the report management system keeps the track record super clean and easy to maintain. ",
            bgImage: seo_slide_02,
            bgColor:'#2C4935',
            icons:[
                {
                    id:'1',
                    src:img1,
                    title:''
                },
                {
                    id:'2',
                    src:img2,
                    title:''
                },
                {
                    id:'3',
                    src:img3,
                    title:''
                },
                {
                    id:'4',
                    src:img4,
                    title:''
                },
                {
                    id:'5',
                    src:img5,
                    title:''
                },
                {
                    id:'6',
                    src:img6,
                    title:''
                },
                {
                    id:'7',
                    src:img7,
                    title:''
                },
                {
                    id:'8',
                    src:img8,
                    title:''
                },
                {
                    id:'9',
                    src:img9,
                    title:''
                },
                {
                    id:'10',
                    src:img10,
                    title:''
                } 
            ]
        },
        {
            id:"6",
            title:"Virtual Assistant",
            slug:"#",
            description:"Offering remote administrative support to manage tasks efficiently, freeing up your time to focus on core business activities. ",
            bgImage: seo_slide_01,
            bgColor:'#A07963',
            icons:[
                {
                    id:'1',
                    src:img1,
                    title:''
                },
                {
                    id:'2',
                    src:img2,
                    title:''
                },
                {
                    id:'3',
                    src:img3,
                    title:''
                },
                {
                    id:'4',
                    src:img4,
                    title:''
                },
                {
                    id:'5',
                    src:img5,
                    title:''
                },
                {
                    id:'6',
                    src:img6,
                    title:''
                },
                {
                    id:'7',
                    src:img7,
                    title:''
                },
                {
                    id:'8',
                    src:img8,
                    title:''
                },
                {
                    id:'9',
                    src:img9,
                    title:''
                },
                {
                    id:'10',
                    src:img10,
                    title:''
                } 
            ]
        }
    ]
}
