import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
// import {BrowserRouter , Routes , Route} from 'react-router-dom';
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  staggerText,
  staggerReveal,
  fadeInUp,
  fadeInDown,
  handleHover,
  handleHoverExit,
  handleCityReturn,
  handleCity,
  staggerRevealClose,
} from "./Animations";

//Location Background Image
import salt from "../../images/header/salt.jpg";
import dallas from "../../images/header/dallas.jpg";
import wyoming from "../../images/header/wyoming.jpg";
import dubai from "../../images/header/dubai.jpg";
import india from "../../images/header/india.jpg";

//Location Icon Image
// import newyorkIcon from "../../images/header/newyork_icon.png";
import dallasIcon from "../../images/header/dallas_icon.png";
import wyomingIcon from "../../images/header/wyoming_icon.png";
import dubaiIcon from "../../images/header/dubai_icon.png";
import indiaIcon from "../../images/header/india_icon.png";
import saltIcon from "../../images/header/salt_icon.png";

//Social Background Image
import instagram from "../../images/social/instagram.jpg";
import facebook from "../../images/social/facebook.jpg";
import youtube from "../../images/social/youtube.jpg";
import linkedIn from "../../images/social/linked.jpg";

import { Box, Grid } from "@mui/material";

import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const cities = [
  { name: "Salt Lack City", image: salt, icon: saltIcon },
  { name: "Dallas", image: dallas, icon: dallasIcon },
  { name: "Wyoming", image: wyoming, icon: wyomingIcon },
  { name: "Dubai", image: dubai, icon: dubaiIcon },
  { name: "India", image: india, icon: indiaIcon },
];
const social = [
  {
    name: "Instagram",
    image: instagram,
    path: "https://www.instagram.com/rebrandgurusofficial/",
    color: "#d6366c",
  },
  {
    name: "Facebook",
    image: facebook,
    path: "https://www.facebook.com/rebrandgurusmarketing",
    color: "#0863F7",
  },
  {
    name: "YouTube",
    image: youtube,
    path: "https://www.youtube.com/channel/UClS9V3u_D4AsWhVOqZ92xrQ",
    color: "#F60002",
  },
  {
    name: "LinkedIn",
    image: linkedIn,
    path: "https://www.linkedin.com/company/74515017/admin/dashboard/",
    color: "#0077AF",
  },
];
const pageLink = [
  { name: "Home", path: "/", animationType: "line1" },
  { name: "Agency", path: "/about-us", animationType: "line2" },
  { name: "Expertise", path: "/Services", animationType: "line3" },
  { name: "Work", path: "/portfolio", animationType: "line2" },
  { name: "Blog", path: "/blog", animationType: "line3" },
  { name: "Support", path: "/contact-us", animationType: "line1" },
];

const HamburgerContent = ({ state, onButtonClick }) => {
  // Create varibles of our dom nodes
  let menuLayer = useRef(null);
  let reveal1 = useRef(null);
  let reveal2 = useRef(null);
  let cityBackground = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let info = useRef(null);
  let socialA = useRef(null);

  useEffect(() => {
    if (state.clicked === false) {
      staggerRevealClose(reveal2, reveal1);
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
      gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
        height: "100%",
      });
      staggerReveal(reveal1, reveal2);
      fadeInUp(info);
      fadeInDown(socialA);
      staggerText(line1, line2, line3);
    }
  }, [state]);

  const handleClick = () => {
    // Call the function passed from props
    if (onButtonClick) {
      onButtonClick();
    }
  };

  return (
    <Box ref={(el) => (menuLayer = el)} className="hamburger-menu">
      <Box
        ref={(el) => (reveal1 = el)}
        className="menu-secondary-background-color"
      ></Box>
      <Box ref={(el) => (reveal2 = el)} className="menu-layer">
        <Box
          ref={(el) => (cityBackground = el)}
          className="menu-city-background"
        ></Box>
        <Box className="MenuWrap">
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <nav className="menu-links">
                <ul>
                  {pageLink.map((user) => {
                    return (
                      <li key={user.name}>
                        <Link
                          onClick={handleClick}
                          onMouseEnter={(e) => handleHover(e)}
                          onMouseOut={(e) => handleHoverExit(e)}
                          ref={(el) => (user.animationType = el)}
                          to={user.path}
                        >
                          {user.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </Grid>
            <Grid item xs={12} md={6} lg={4} alignItems={'center'}>
              <Box
                sx={{p: 4, pl:0,  textAlign: "left" }}
                ref={(el) => (info = el)}
                className="HeaderContactInfo  height100"
              >
                <Box className="h_info_item">
                  <Box className="h_info_icon">
                    <MarkunreadOutlinedIcon />
                  </Box>
                  <Link to={"mailto:info@rebrandgurus.com"}>
                    info@rebrandgurus.com
                  </Link>
                </Box>
                <Box className="h_info_item">
                  <Box className="h_info_icon">
                    <PhoneOutlinedIcon />
                  </Box>
                  <Link to={"tel:+14353950079"}><label>Salt Lake City Office</label>+1 (435)-395-0079</Link><br/> <br/> 
                  <Link to={"tel:+14692058133"}><label>Dallas Office</label>+1 (469)-205-8133</Link>
                </Box>
                <Box className="h_info_item location">
                  <Box className="h_info_icon">
                    <FmdGoodOutlinedIcon />
                  </Box>
                  <Link to={"https://www.google.com/maps/place/ReBrand+Gurus/@44.7980451,-106.9551321,17.25z/data=!4m6!3m5!1s0x5335fbc78df01a2f:0x1d7207e6f2ccf79f!8m2!3d44.7977689!4d-106.9549034!16s%2Fg%2F11s0vlc51c?entry=ttu"} target="_blank">
                  30 N Gould St Suite R, Sheridan, WY 82801, United States
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box
                sx={{ p: 0, textAlign: "center" }}
                ref={(el) => (info = el)}
                className="socialBox  height100"
              >
                <Box className="social_Wrp" ref={(el) => (socialA = el)}>
                  {social.map((list) => (
                    <Box
                      className={`${list.name.toLowerCase()} social_item`}
                      key={list.color}
                    >
                      <Box
                        component="a"
                        href={`${list.path}`}
                        sx={{ backgroundImage: "url(" + list.image + ")" }}
                        onMouseEnter={() =>
                          handleCity(list.image, cityBackground)
                        }
                        onMouseOut={() => handleCityReturn(cityBackground)}
                      >
                        <Box
                          className="social_overlay"
                          bgcolor={list.color}
                        ></Box>
                      </Box>
                      <Box className="socialText">{list.name}</Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="locations" ref={(el) => (info = el)}>
            {cities.map((el) => (
              <Box
                key={el.name}
                component="img"
                onMouseEnter={() => handleCity(el.image, cityBackground)}
                onMouseOut={() => handleCityReturn(cityBackground)}
                src={el.icon}
                className="locationItem"
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HamburgerContent;
