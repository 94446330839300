import React from 'react';
// import { gsap } from 'gsap';

// import MainVideo from './../../images/video-main-bg.mp4'
import { Box } from '@mui/material';


import './css/homeBanner.css'
import TrailImages from './homeBanner/banner';

const BackgroundVideo = () => {
  
  return (
    <>
      <Box
        sx={{py:8}}
      >
      <TrailImages />
      </Box>
    
    
    </>
  )
}

export default BackgroundVideo