// export const LOGO = '../logo.png';
import { motion } from "framer-motion";


import {  createTheme } from '@mui/material/styles';



export const hyGraphToken = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3Mjg0ODk5OTIsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuaHlncmFwaC5jb20vdjIvY20xZm93cnd2MDE4bzA3dXVtdHFhdGRnMy9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC11cy13ZXN0LTIuaHlncmFwaC5jb20vIiwic3ViIjoiMGMxOGEzZDUtNzNmYi00MTllLWJjNWItYjcxNWFlYWYyNjhhIiwianRpIjoiY20yMjJiYTh1MHF2ejA3bG81M3k1YXYxYyJ9.3pv8QzWcXPaqjJigLgmcTM6VO9lZi2URJ_CC4N3nr1s2GppCP97NiNFg4I0yPyTkRkuZAQ1pDnnl2Wsu_uhZ4Y8760iYRgiW6-kTM1hpRZPEIZUrCgFF7KBIL6kYBMRszIx5qGrFX7N2MG0XbNOXDU2dQ2SNIisLyMjvOj8z4uMElG5nIfRdE-s50Fi-L1UkflsMMpJD8koXBMv2L6V0optDFGRAzmRmO1NasORDNkj6IgEQsG5jR62B49KQjKTTOTqaw18Tnse_M-vRLPA4jiP8viWXEJZS5y9mb8I4vyjSQuLQdK8rMiuN4K2SemKwBXN7clUoOyGOOkS-6-qnIAM0vYUTNSSP4g-YQNmY-PA7ZpdT18tVAxjdSAP7p5N0ffwI-PMqlOsugRWNaTgPYzIG_DN_ePKuAmc-K6-LZgXmcJKvlTOTJyDh5SXYCYYTXWecB6NJQqkPI7fiioZ51aP9FhmfsJplyifDDLFbVnH6JM54lU_oMWLXCmSrSnGW9W2oG8WLvZL99pSJEy39OdcHtZcTISWR2QQLNDNjqiXvOmjrreFqUL0LqY7-Cp5Cu8pcLBUJxdK-11UTGAuJLGJ8rfEE-1W25uV2A-s3uPw189nAcqfFdzEaOWXJtXTewSaxB5HicyeztJv7ezx6d0-1y8Jf4Xtj4K5O6dUIG4w`
// const PROJECT_ID = "8355c9dca27740c4b22e5661116c18c3";

export const endpointURL = `https://us-west-2.cdn.hygraph.com/content/cm1fowrwv018o07uumtqatdg3/master`


export const PortalUrl = "https://wp.rebrandgurus.com/"
const font =  "'Space Grotesk', sans-serif";
const fontHeading =  'Recoleta';

export const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#0e191d',
        dark: '#1D1D1D',
        bg_0: '#121212',
        bg_1: '#171717',
        bg_2: '#222',
        bg_3: '#111',
      },
      primary: {
        light: '#8B9C8C',
        main: '#2E5E53',
        dark: '#8b9c8c',
        contrastText: '#D7D7D7',
        
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      
    },
    shape : {
      borderRadiusSearch :'50px',
    },
    typography: {
        fontFamily: font,
        h1: {
            fontFamily: fontHeading,
            '@media (min-width:960px)': {
              fontSize: '6rem', 
            },
            
        },
        h2: {
            fontFamily: fontHeading,
            '@media (max-width:600px)': {
              fontSize: '2.5rem', 
            },
            '@media (min-width:960px)': {
              fontSize: '4rem', 
            },
        },
        h3: {
            fontFamily: fontHeading,
            '@media (max-width:600px)': {
              fontSize: '1.9rem', 
            },
            '@media (min-width:960px)': {
              fontSize: '2.4rem', 
            },
            '@media (min-width:1024px)': {
              fontSize: '2.8rem', 
            },
            '@media (min-width:1199px)': {
              fontSize: '3rem', 
            },
            '@media (min-width:1300px)': {
              fontSize: '3.5rem', 
            },
        },
        h4: {
            fontFamily: fontHeading,
        },
        h5: {
            fontFamily: fontHeading,
        },
        h6: {
            fontFamily: fontHeading,
        },    
    },
    components: {
        MuiButton: {
            variants: [
                {
                  props: { variant: 'roundBtn' },
                  style: {
                    backgroundColor: 'transparent', 
                    color: '#fff', 
                    lineHeight:'normal',
                    fontSize:'16px',
                    borderRadius:'100%',
                    border:'0px solid #fff',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#000', 
                        borderColor:'transparent'
                    },
                  },
                },
            ],
        }
    }
  });



  export  const AnimatedParagraph = ({ children }) => {
    return (
      <motion.p  initial={{ y: 100, opacity: 0 }}
      whileInView={{ x: -0, y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{
        type: "spring",
        duration: 2,
      }}>
        {children}
      </motion.p>
    );
  };