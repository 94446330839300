import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


import './css/faqs.css';
import { Box } from "@mui/material";
import SectionHeading from "../../components/SectionHeading";


const ServiceFAQS = ({accordionData }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box component={"section"} bgcolor={"#000"} position={"relative"} py={8}>
      <SectionHeading title="FAQs" />
      {accordionData.map((data) => (
        <Accordion
          key={data.id}
          expanded={expanded === data.id}
          onChange={handleChange(data.id)}
         sx={{
            backgroundColor:'primary.light'
         }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${data.id}bh-content`}
            id={`${data.id}bh-header`}
            sx={{ color: "secondary.contrastText", fontSize: '22px' }}
          >
            <Typography sx={{ color: "secondary.contrastText", fontSize: '22px' }}>
              {data.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ color: "secondary.contrastText", fontSize: '18px' }}>
            <Typography dangerouslySetInnerHTML={{ __html: data.answer }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ServiceFAQS;
