import React, { useEffect, useRef, useState } from "react";

import WelcomeAbout from "./about/WelcomeAbout";
import OurVission from "./about/OurVission";
import { Box } from "@mui/material";

import ScrollSpy from "react-ui-scrollspy";
import { Helmet } from "react-helmet";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ClientReviewVideos from "./about/ClientReviewVideos";
import BuiltWithUs from "./about/BuiltWithUs";
import { gql } from "graphql-request";
import hygraph from "../GraphqlClient";
gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
  const aboutMainContainerRef = useRef(null);
  const firstmainAboutHref = useRef(null);
  const containerRef = useRef(null);
  const tableContentRef = useRef(null);
  const figureRef = useRef(null);
  const videoRef = useRef(null);
  const captionRef = useRef(null);
  const [metaTitle, setMetaTitle] = useState("About Us - Discover Our Story and Mission");
  const [metaDescription, setMetaDescription] = useState("Discover our story and mission at ReBrandGurus.com. Learn how we strive to make a difference and achieve our goals to serve you better.");

  useEffect(() => {
    const fetchAboutUsData = async () => {
      const query = gql`
        query {
          page(where: {slug: "about-us"}) {
            metaTitle
            metaDescription
            slug
            title
          }
        }
      `;
    
      try {
        const data = await hygraph.request(query);
        console.log("Fetched data:", data); // Log the fetched data
        if (data.page) {
          setMetaTitle(data.page.metaTitle);
          setMetaDescription(data.page.metaDescription);
        } else {
          console.error("No data found for the specified slug.");
        }
      } catch (error) {
        console.error("Error fetching About Us data:", error);
      }
    };

    fetchAboutUsData();



    const setVwVh = () => {
      const vw = document.documentElement.clientWidth / 100;
      const vh = document.documentElement.clientHeight / 100;
      document.documentElement.style.setProperty("--vw", `${vw}px`);
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    //Table COntent Sticky
    gsap.timeline({
      scrollTrigger: {
        trigger: tableContentRef.current,
        start: "top 20px",
        end: () => window.innerHeight * 10,
        scrub: 3,
        pin: tableContentRef.current,
        markers: false,
      },
    });

    const getClipPath = () => {
      const maxWidth = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--container-width"
        )
      );
      const gap = 24;
      let insetX = (window.innerWidth - maxWidth - gap) / 2;
      let insetY = (window.innerHeight - maxWidth - gap) / 2;

      insetX = insetX > 0 ? insetX : gap;
      insetY = insetY > 0 ? insetY : gap;

      return `inset(${insetY}px ${insetX}px)`;
    };

    let isPlaying = false;

    const videoPause = () => {
      if (videoRef.current && !videoRef.current.paused && isPlaying) {
        videoRef.current.pause();

        gsap.to(captionRef.current, {
          y: "100%",
          duration: 0.5,
        });
      }
    };

    const videoPlay = async () => {
      if (videoRef.current && videoRef.current.paused && !isPlaying) {
        gsap.to(captionRef.current, {
          y: "0%",
          duration: 0.5,
        });

        return await videoRef.current.play();
      }
    };

    gsap.fromTo(
      figureRef.current,
      {
        clipPath: getClipPath,
        y: "0%",
      },
      {
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top top",
          end: "top top",
          scrub: true,
          markers: false,
        },
        y: "0%",
        duration: 0.5,
        onStart: () => {
          videoPause();
        },
      }
    );

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top top",
        end: () => aboutMainContainerRef.innerHeight,
        scrub: true,
        pin: containerRef.current,
      },
    });

    tl.fromTo(
      figureRef.current,
      {
        clipPath: getClipPath,
      },
      {
        clipPath: "inset(0px 0px)",
        duration: 0.5,
        onUpdate: () => {
          videoPause();
        },
        onComplete: () => {
          videoPlay();
        },
      }
    );

    tl.fromTo(
      figureRef.current,
      {
        opacity: 1,
      },
      {
        opacity: 1,
        duration: 1,
        onUpdate: () => {
          videoPlay();
        },
        onComplete: () => {
          videoPause();
        },
      }
    );

    tl.fromTo(
      figureRef.current,
      {
        clipPath: "inset(0px 0px)",
      },
      {
        clipPath: getClipPath,
        duration: 0.5,
      }
    );

    document.addEventListener("DOMContentLoaded", setVwVh);
    document.addEventListener("DOMContentLoaded", () => {
      isPlaying = false; // Reset isPlaying on page load
      videoPlay(); // Initial play
    });
    document.addEventListener("resize", setVwVh);

    return () => {
      // Cleanup ScrollTrigger on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <>
    <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Box ref={firstmainAboutHref}>
        <ScrollSpy  scrollThrottle={100} useBoxMethod={false}>
          <WelcomeAbout
            // aboutMainContainerRef={aboutMainContainerRef}
            tableContentRef={tableContentRef}
            containerRef={containerRef}
            figureRef={figureRef}
            videoRef={videoRef}
          />
          <OurVission />
          <ClientReviewVideos />
          <BuiltWithUs />
        </ScrollSpy>
      </Box>
    </>
  );
};

export default AboutUs;
