import { Box } from "@mui/material";
import React, { useEffect } from "react";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import scrolltop from "../images/scroll_top.svg";

import "./css/scrolltop.css";

gsap.registerPlugin(ScrollTrigger);

export const ScrollTop = () => {
    const [scroll, setScroll] = React.useState("");
    function getScrollPercent() {
        const html = document.documentElement,
          body = document.body,
          st = "scrollTop",
          sh = "scrollHeight";
    
        const scrollPercent = Math.round(
          ((html[st] || body[st]) / ((html[sh] || body[sh]) - html.clientHeight)) *
            100
        );
    
        setScroll(isNaN(scrollPercent) ? "" : scrollPercent);
      }
    
      React.useEffect(() => {
        window.addEventListener("scroll", getScrollPercent);
    
        return () => {
          window.removeEventListener("scroll", getScrollPercent);
        };
      }, []);


      //Scroll top
      const handleScrollTop = () => {
        const scrollToTop = () => {
          const currentPosition = window.scrollY;
          if (currentPosition > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, currentPosition - currentPosition / 8);
          }
        };
      
        scrollToTop();
      };

      //Scroll top circle Rotate
      useEffect(() => {
        const handleScroll = () => {
          const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
          const rotation = scrollPercentage * 2.4;
    
          gsap.to('.scrollImg', { rotation: rotation, duration: 0.4, ease: 'none' });
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
      <Box className="scroll-to-top" onClick={handleScrollTop}>
        <Box
          component="img"
          className="scrollImg"
          src={scrolltop}
          alt="scroll"
        />
        <Box className="box-number v-middle">
          <span>{`${scroll}%`}</span>
        </Box>
      </Box>
    </>
  );
};
