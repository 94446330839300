import { Box, Button, Stack, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";

import { motion } from "framer-motion";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ColorizeSharpIcon from "@mui/icons-material/ColorizeSharp";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";

import SmilryImage from "../../images/smiley.gif";

import { ReactSketchCanvas } from "react-sketch-canvas";

import emailjs from "emailjs-com";

import "./contactform.css";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ContactForm = () => {
  const myRef = useRef();

  const [color, setColor] = useState("#FF9776");

  function changeColor(evt) {
    setColor(evt.currentTarget.value);
  }

  const handleClear = () => {
    if (myRef.current) {
      myRef.current.clearCanvas();
    }
  };

  const sketchStyle = {
    border: "none",
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    if (myRef.current) {
      myRef.current.exportImage("png").then((dataUrl) => {
        const base64Image = dataUrl.split(",")[1]; // Remove the data URL part

        const emailData = {
          ...formData,
          attachment: base64Image,
        };

        emailjs
          .send("service_xmhw5y7", "template_lx7wlec", emailData, "TkMsz9TqPVn0kRtJb")
          .then(
            (result) => {
              console.log(result.text);
              alert("Email sent successfully!");
            },
            (error) => {
              console.log(error.text);
              alert("Failed to send email.");
            }
          );
      });
    } else {
      alert("Sketch not available.");
    }
  };


  return (
    <>
      <Box className="formWrp">
        <Box component="form" className="formBox" onSubmit={sendEmail} method="post">
          <motion.div
            className="formtopBox"
            initial={{ scale: 0.7, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.7, opacity: 0 }}
            transition={{
              duration: 1,
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={7.2}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: "spring",
                    // duration: 1,
                    delay: 1.4,
                  }}
                  className="messageBoxWrp"
                >
                  <Box className="messageBox_header">
                    Yay! Emails! <br />
                    Please type your message below, or send me an email at{" "}
                    <BootstrapTooltip
                      placement="top"
                      title="Copy to clipboard ?"
                    >
                      <a href="mailto:info@rebrandgurus.com">
                        info@rebrandgurus.com
                      </a>
                    </BootstrapTooltip>
                    .
                  </Box>
                  <Box className="messageBoxContent">
                    <Typography
                      for="outlined-adornment-weight"
                      sx={{ marginTop: "20px" }}
                      fontSize="30px"
                      component="h3"
                      variant="h3"
                    >
                      Hey Gurus, 
                      <Box
                        component="img"
                        className="smileyIcon"
                        src={SmilryImage}
                      />
                    </Typography>
                    <textarea
                      className="messageInput"
                      autoFocus
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </Box>
                </motion.div>
              </Grid>
              <Grid item  xs={12} md={4.8} className="p-relative">
                <motion.div
                  initial={{ height: 0 }}
                  whileInView={{ height: "100%" }}
                  exit={{ height: 0 }}
                  transition={{
                    type: "spring",
                    duration: 1,
                    delay: 1.2,
                  }}
                  className="verticleLine"
                ></motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: "spring",
                    // duration: 1,
                    delay: 1.4,
                  }}
                  className="skectchBox"
                >
                  <Box className="sketchText">
                    Live life on the edge, spice up your message with a drawing:
                  </Box>
                  <ReactSketchCanvas
                    ref={myRef}
                    width="100%"
                    style={sketchStyle}
                    height="100%"
                    strokeWidth={5}
                    strokeColor={color}
                    canvasColor="transparent"
                    clearCanvas
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                    className="steckOptionIcon"
                  >
                    <Tooltip
                      title="Choose Your Color"
                      className="colorPickerIcon"
                    >
                      <IconButton for="favcolor">
                        <ColorizeSharpIcon />
                        <input
                          type="color"
                          id="favcolor"
                          name="favcolor"
                          value={color}
                          onChange={(evt) => changeColor(evt)}
                          className="colorPicker"
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear" className="clearBtn">
                      <IconButton onClick={handleClear}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </motion.div>
              </Grid>
            </Grid>
          </motion.div>
          <Box className="formBottomBox">
            <motion.div
              initial={{ scale: 0.7, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.7, opacity: 0 }}
              transition={{
                duration: 1,
              }}
              className="inputBoxWrp"
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: "spring",
                  delay: 1.4,
                }}
                className="form_b_input email"
              >
                <input
                  type="email"
                  placeholder="Email"
                  className="inputBox"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </motion.div>
              <motion.div
                initial={{ height: 0 }}
                whileInView={{ height: "100%" }}
                exit={{ height: 0 }}
                transition={{
                  type: "spring",
                  duration: 1,
                  delay: 1.2,
                }}
                className="verticleLine"
              ></motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: "spring",
                  delay: 1.4,
                }}
                className="form_b_input p-relative name"
              >
                <input
                  type="text"
                  placeholder="Full Name"
                  className="inputBox"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </motion.div>
            </motion.div>

            <motion.div
              initial={{ scale: 0.7, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.7, opacity: 0 }}
              transition={{
                duration: 1,
              }}
              className="form_bottonBox"
            >
              <Button
                className="submitButton"
                endIcon={<SendIcon />}
                type="submit"
                
              >
                Send Message
              </Button>
            </motion.div>
          </Box>
        </Box>

        
      </Box>
    </>
  );
};

export default ContactForm;
