import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import EastIcon from '@mui/icons-material/East';

// import { useParams } from "react-router-dom";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { ScrollTop } from "../../components/ScrollTop";
import EmblaCarousel from "../../components/EmblaCarousel";


//CSS
import "./css/pageStucture.css";

import { ServicesPageData } from "../../Data";
import CustomButton from "../../components/CustomButton";

gsap.registerPlugin(ScrollTrigger);

const ServiceDetailsLayout = ({id, content, slug}: Props) => {
  // let { pageID } = useParams();
 
  useEffect(() => {

    const handleScroll = () => {
      const scrollPercentage =
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
        100;
      const rotation = scrollPercentage * 10;

      gsap.to(".page_title", { y: `${rotation}`, duration: 1, ease: "none" });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const OPTIONS = { dragFree: false, loop: true };
  const SLIDE_COUNT = 3;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());


  const TitleWithSpan = ()=> (
    <span>
      {ServicesPageData.services[id].title}
      <span className="letter-stroke"> {ServicesPageData.services[id].title}</span>
    </span>
  );

  return (
    <>
      <Box component="section" className="servicePageWrp">
        <Box className="pageFeaturedImageSec ">
          <EmblaCarousel slides={SLIDES} options={OPTIONS} sliderImage={ServicesPageData.services[id].sliderImage} />
          <Box className="pageFeature_content">
            <Typography
              component="h1"
              variant="h1"
              fontSize="60px"
              fontWeight="normal"
            >
              {ServicesPageData.services[id].mainHeading}
            </Typography>
            <p className="description border-before">{ServicesPageData.services[id].description}</p>
            <CustomButton endIcon={<EastIcon />}>Get a Quote</CustomButton>
            
          </Box>
        </Box>
        <Box className="servicewrp">
          <Box className="page_sidebox">
            <Box className="box-first-left">
              <Box className="page-active">
                <h2>Service</h2>
              </Box>
              <ScrollTop />
            </Box>
            <Box className="box-second-left">
              <Box className="page_title">
                  {[...Array(3)].map((_, index) => (
                    <TitleWithSpan key={index} text={index} />
                  ))}
              </Box>
            </Box>
          </Box>
          <Box className="page_contentbox">
            {content}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ServiceDetailsLayout;
